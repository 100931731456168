import { SeatEntry } from '../types/floorPlan';

export const floorSettings = {
  width: 1485,
  height: 485,
  viewport: [-742.5, -242.5],
  zoom: 2,
  maxZoom: 3,
  minZoom: 0.5,
};

export const wallSettings = {
  width: 300,
};

export const tableSettings = {
  square: {
    width: 100,
    height: 100,
  },
  rectangle: {
    width: 150,
    height: 100,
  },
  circle: {
    width: 100,
    height: 100,
  },
};

export const seatSettings = {
  width: 30,
  height: 3,
};

export const getStrokeWight = (shape: string, width: number, height: number) => {
  if (shape === 'circle') {
    const circuit = 2 * Math.PI * (width / 2);

    return circuit < 130 ? 2 : 3;
  }

  const circuit = (width + height) * 2;

  return circuit < 250 ? 2 : 3;
};

export const timelineSettings = {
  interval: 15,
};

export const tableColors = {
  available: {
    primary: '#7a82a3',
    secondary: '#e6ebf7',
  },
  booked: {
    primary: '#1354b7',
    secondary: '#cce1ff',
  },
  approved: {
    primary: '#1354b7',
    secondary: '#cce1ff',
  },
  arrived: {
    primary: '#2fa32f',
    secondary: '#daf3ce',
  },
  pending: {
    primary: '#ebb74e',
    secondary: '#ffecb8',
  },
  waiting_list: {
    primary: '#ebb74e',
    secondary: '#ffecb8',
  },
  walk_in: {
    primary: '#7e5cbe',
    secondary: '#e4d9ff',
  },
  not_arrived: {
    primary: '#d0483e',
    secondary: '#fad8d4',
  },
  cancelled: {
    primary: '#7a82a3',
    secondary: '#e6ebf7',
  },
  blocked: {
    primary: '#e13a35',
    secondary: '#fad8d4',
  },
  not_available: {
    primary: '#e13a35',
    secondary: '#fae4e1',
  },
};

export const renderSeats = (shape: string, width: number, height: number, s: number) => {
  if (shape === 'circle') {
    let seatSettingsWidth = seatSettings.width;

    /**
     * Sometimes if there is a lot of seats or table is scaled to
     * small size instead of dotted line representing seats we got
     * one regular line. If that happen we need to reduce seatSettingWidth.
     */
    const breakRatio = 1.8;
    const seatToWidthRatio = (width * Math.PI) / (seatSettingsWidth * s);
    if (seatToWidthRatio < breakRatio) {
      const reduceRatio = Math.round((seatToWidthRatio * 100) / breakRatio);
      seatSettingsWidth = seatSettingsWidth * (reduceRatio / 100);
    }

    return {
      strokeDasharray: [seatSettingsWidth, (width * Math.PI) / s - seatSettingsWidth].join(' '),
      strokeDashoffset: seatSettingsWidth / 2,
    };
  }

  const seats = [0, 0, 0, 0]; //[left, top, right, bottom]

  for (let i = 1; i <= s; i++) {
    const side = i % 4;

    switch (side) {
      case 0:
        seats[3]++;
        break;
      case 1:
        seats[0]++;
        break;
      case 2:
        seats[2]++;
        break;
      case 3:
        seats[1]++;
        break;
      default:
        return;
    }
  }

  /**
   * Sometimes if there is a lot of seats or table is scaled to
   * small size instead of dotted line representing seats we got
   * one regular line. If that happen we need to reduce seatSettingWidth.
   */

  // prepend that there is at least 6 seats in a table for better calculation.
  const minSeats = s < 6 ? 6 : s;
  const circuitToSeatsRatio = Math.round((((width + height) * 2) / minSeats) * 100) / 100;
  const breakRatio = 46;
  let widthSettings = seatSettings.width;

  if (circuitToSeatsRatio < breakRatio) {
    const reduceRatio = Math.round((circuitToSeatsRatio * 100) / breakRatio);
    widthSettings = (seatSettings.width - 10) * (reduceRatio / 100);
  }

  const left: SeatEntry[] = new Array(seats[0] + 1)
    .fill(widthSettings)
    .map((v, i) =>
      i === seats[0]
        ? [{ t: 'gap', v: (height - v * seats[0]) / (seats[0] + 1) }]
        : [
            { t: 'gap', v: (height - v * seats[0]) / (seats[0] + 1) },
            { t: 'seat', v },
          ],
    )
    .reduce((acc, curr) => acc.concat(curr), []);

  const top: SeatEntry[] = new Array(seats[1] + 1)
    .fill(widthSettings)
    .map((v, i) =>
      i === seats[1]
        ? [{ t: 'gap', v: (width - v * seats[1]) / (seats[1] + 1) }]
        : [
            { t: 'gap', v: (width - v * seats[1]) / (seats[1] + 1) },
            { t: 'seat', v },
          ],
    )
    .reduce((acc, curr) => acc.concat(curr), []);

  const right: SeatEntry[] = new Array(seats[2] + 1)
    .fill(widthSettings)
    .map((v, i) =>
      i === seats[2]
        ? [{ t: 'gap', v: (height - v * seats[2]) / (seats[2] + 1) }]
        : [
            { t: 'gap', v: (height - v * seats[2]) / (seats[2] + 1) },
            { t: 'seat', v },
          ],
    )
    .reduce((acc, curr) => acc.concat(curr), []);

  const bottom: SeatEntry[] = new Array(seats[3] + 1)
    .fill(widthSettings)
    .map((v, i) =>
      i === seats[3]
        ? [{ t: 'gap', v: (width - v * seats[3]) / (seats[3] + 1) }]
        : [
            { t: 'gap', v: (width - v * seats[3]) / (seats[3] + 1) },
            { t: 'seat', v },
          ],
    )
    .reduce((acc, curr) => acc.concat(curr), []);

  const strokeDasharray = [...left.slice(1), ...top, ...right, ...bottom, left[0]]
    .reduce((acc: SeatEntry[], curr, i) => {
      const prev = i > 0 ? acc[acc.length - 1] : null;
      if (!!prev && prev.t === 'gap' && curr.t === 'gap') {
        prev.v += curr.v;
        return acc;
      } else {
        return [...acc, curr];
      }
    }, [])
    .map((s) => s.v)
    .join(' ');

  const strokeDashoffset = height - (height - left[1].v * seats[0]) / (seats[0] + 1);

  return { strokeDasharray, strokeDashoffset };
};
