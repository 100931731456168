import React from 'react';
import { FloorPlanTable } from 'types/floorPlan';
import { Statuses } from 'types/reservation';
import { getStrokeWight, renderSeats, tableColors, tableSettings } from 'utils/floorPlan';

interface Props {
  table: FloorPlanTable;
  status?: Statuses;
  selectedStatus?: Statuses;
  selected?: boolean;
  onClick?: (id: number) => void;
}

const FloorTable: React.FC<Props> = ({
  table,
  status,
  selected,
  selectedStatus,
  onClick = () => {},
}) => {
  if (!table.position) return null;

  // @ts-ignore
  const width = table.position.width || tableSettings[table.position.shape]?.width || 0;
  // @ts-ignore
  const height = table.position.height || tableSettings[table.position.shape]?.height || 0;
  const transform = `rotate(${table.position.angle} ${table.position.x} ${table.position.y})`;

  const handleClick: React.MouseEventHandler<SVGGElement> = () => {
    if (status === Statuses.Blocked) return;
    onClick(table.nid);
  };

  const statusColor =
    // @ts-ignore
    tableColors[
      selected && selectedStatus ? selectedStatus : !selected && status ? status : 'available'
    ];

  console.log('dupsko', status)

  return (
    <g
      onClick={handleClick}
      className={`floor-plan__table${
        status === Statuses.Blocked ? ' floor-plan__table--blocked' : ''
      }`}
    >
      <g transform={transform}>
        {table.position.shape === 'circle' ? (
          <circle
            cx={table.position.x}
            cy={table.position.y}
            r={width / 2}
            fill={statusColor.secondary}
            stroke={statusColor.primary}
            strokeWidth={getStrokeWight(table.position.shape, width, height)}
            {...renderSeats(table.position.shape, width, height, table.covers_max)}
          />
        ) : (
          <rect
            x={table.position.x - width / 2}
            y={table.position.y - height / 2}
            width={width}
            height={height}
            strokeWidth={getStrokeWight(table.position.shape, width, height)}
            fill={statusColor.secondary}
            stroke={statusColor.primary}
            {...renderSeats(table.position.shape, width, height, table.covers_max)}
          />
        )}
        {selected && !selectedStatus && (
          <rect
            x={table.position.x - 15 - width / 2}
            y={table.position.y - 15 - height / 2}
            width={width + 30}
            height={height + 30}
            stroke="#0700c5"
            strokeWidth="3px"
            fill="none"
            rx="6"
          />
        )}
      </g>
      <text
        x={table.position.x}
        y={table.position.y + 8}
        fontSize="16"
        fontWeight={400}
        fill={statusColor.primary}
        textAnchor="middle"
      >
        {table.title}
      </text>
    </g>
  );
};

export default FloorTable;
